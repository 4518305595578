import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const Setting = () => import('@/entities/setting/setting.vue');
const SettingUpdate = () => import('@/entities/setting/setting-update.vue');
const SettingDetails = () => import('@/entities/setting/setting-details.vue');

const People = () => import('@/entities/people/people.vue');
const PeopleUpdate = () => import('@/entities/people/people-update.vue');
const PeopleDetails = () => import('@/entities/people/people-details.vue');

const PeopleMetadata = () => import('@/entities/people-metadata/people-metadata.vue');
const PeopleMetadataUpdate = () => import('@/entities/people-metadata/people-metadata-update.vue');
const PeopleMetadataDetails = () => import('@/entities/people-metadata/people-metadata-details.vue');

const Badge = () => import('@/entities/badge/badge.vue');
const BadgeUpdate = () => import('@/entities/badge/badge-update.vue');
const BadgeDetails = () => import('@/entities/badge/badge-details.vue');

const BadgeHistory = () => import('@/entities/badge-history/badge-history.vue');
const BadgeHistoryDetails = () => import('@/entities/badge-history/badge-history-details.vue');

const Identifier = () => import('@/entities/identifier/identifier.vue');
const IdentifierUpdate = () => import('@/entities/identifier/identifier-update.vue');
const IdentifierDetails = () => import('@/entities/identifier/identifier-details.vue');

const Group = () => import('@/entities/group/group.vue');
const GroupUpdate = () => import('@/entities/group/group-update.vue');
const GroupDetails = () => import('@/entities/group/group-details.vue');

const Department = () => import('@/entities/department/department.vue');
const DepartmentUpdate = () => import('@/entities/department/department-update.vue');
const DepartmentDetails = () => import('@/entities/department/department-details.vue');

const GroupPermission = () => import('@/entities/group-permission/group-permission.vue');
const GroupPermissionUpdate = () => import('@/entities/group-permission/group-permission-update.vue');
const GroupPermissionDetails = () => import('@/entities/group-permission/group-permission-details.vue');

const Prodotto = () => import('@/entities/prodotto/prodotto.vue');
const ProdottoUpdate = () => import('@/entities/prodotto/prodotto-update.vue');
const ProdottoDetails = () => import('@/entities/prodotto/prodotto-details.vue');

const Kit = () => import('@/entities/kit/kit.vue');
const KitUpdate = () => import('@/entities/kit/kit-update.vue');
const KitDetails = () => import('@/entities/kit/kit-details.vue');

const Variante = () => import('@/entities/variante/variante.vue');
const VarianteUpdate = () => import('@/entities/variante/variante-update.vue');
const VarianteDetails = () => import('@/entities/variante/variante-details.vue');

const ProdottoObject = () => import('@/entities/prodotto-object/prodotto-object.vue');
const ProdottoObjectDetails = () => import('@/entities/prodotto-object/prodotto-object-details.vue');

const ProdottoObjectMetadata = () => import('@/entities/prodotto-object-metadata/prodotto-object-metadata.vue');
const ProdottoObjectMetadataUpdate = () => import('@/entities/prodotto-object-metadata/prodotto-object-metadata-update.vue');
const ProdottoObjectMetadataDetails = () => import('@/entities/prodotto-object-metadata/prodotto-object-metadata-details.vue');

const ProdottoObjectMetadataValue = () => import('@/entities/prodotto-object-metadata-value/prodotto-object-metadata-value.vue');
const ProdottoObjectMetadataValueUpdate = () =>
  import('@/entities/prodotto-object-metadata-value/prodotto-object-metadata-value-update.vue');
const ProdottoObjectMetadataValueDetails = () =>
  import('@/entities/prodotto-object-metadata-value/prodotto-object-metadata-value-details.vue');

const VarianteAttributo = () => import('@/entities/variante-attributo/variante-attributo.vue');
const VarianteAttributoUpdate = () => import('@/entities/variante-attributo/variante-attributo-update.vue');
const VarianteAttributoDetails = () => import('@/entities/variante-attributo/variante-attributo-details.vue');

const ValoreAttributo = () => import('@/entities/valore-attributo/valore-attributo.vue');
const ValoreAttributoUpdate = () => import('@/entities/valore-attributo/valore-attributo-update.vue');
const ValoreAttributoDetails = () => import('@/entities/valore-attributo/valore-attributo-details.vue');

const Categoria = () => import('@/entities/categoria/categoria.vue');
const CategoriaUpdate = () => import('@/entities/categoria/categoria-update.vue');
const CategoriaDetails = () => import('@/entities/categoria/categoria-details.vue');

const Magazzino = () => import('@/entities/magazzino/magazzino.vue');
const MagazzinoUpdate = () => import('@/entities/magazzino/magazzino-update.vue');
const MagazzinoDetails = () => import('@/entities/magazzino/magazzino-details.vue');

const PermessoGroup = () => import('@/entities/permesso-group/permesso-group.vue');
const PermessoGroupUpdate = () => import('@/entities/permesso-group/permesso-group-update.vue');
const PermessoGroupDetails = () => import('@/entities/permesso-group/permesso-group-details.vue');

const PermessoPeople = () => import('@/entities/permesso-people/permesso-people.vue');
const PermessoPeopleUpdate = () => import('@/entities/permesso-people/permesso-people-update.vue');
const PermessoPeopleDetails = () => import('@/entities/permesso-people/permesso-people-details.vue');

const Movimento = () => import('@/entities/movimento/movimento.vue');
const MovimentoDetails = () => import('@/entities/movimento/movimento-details.vue');

const Alloggiamento = () => import('@/entities/alloggiamento/alloggiamento.vue');
const AlloggiamentoUpdate = () => import('@/entities/alloggiamento/alloggiamento-update.vue');
const AlloggiamentoDetails = () => import('@/entities/alloggiamento/alloggiamento-details.vue');

const VassoioWorkbox = () => import('@/entities/vassoio-workbox/vassoio-workbox.vue');
const VassoioWorkboxUpdate = () => import('@/entities/vassoio-workbox/vassoio-workbox-update.vue');
const VassoioWorkboxDetails = () => import('@/entities/vassoio-workbox/vassoio-workbox-details.vue');

const ImpostazioniScorte = () => import('@/entities/impostazioni-scorte/impostazioni-scorte.vue');
const ImpostazioniScorteUpdate = () => import('@/entities/impostazioni-scorte/impostazioni-scorte-update.vue');
const ImpostazioniScorteDetails = () => import('@/entities/impostazioni-scorte/impostazioni-scorte-details.vue');

const NotificationRule = () => import('@/entities/notification-rule/notification-rule.vue');
const NotificationRuleUpdate = () => import('@/entities/notification-rule/notification-rule-update.vue');
const NotificationRuleDetails = () => import('@/entities/notification-rule/notification-rule-details.vue');

const NotificationWeb = () => import('@/entities/notification-web/notification-web.vue');
const NotificationWebUpdate = () => import('@/entities/notification-web/notification-web-update.vue');
const NotificationWebDetails = () => import('@/entities/notification-web/notification-web-details.vue');

const NotificationEmail = () => import('@/entities/notification-email/notification-email.vue');
const NotificationEmailUpdate = () => import('@/entities/notification-email/notification-email-update.vue');
const NotificationEmailDetails = () => import('@/entities/notification-email/notification-email-details.vue');

const Session = () => import('@/entities/session/session.vue');
const SessionDetails = () => import('@/entities/session/session-details.vue');

// prettier-ignore
const Language = () => import('@/entities/language/language.vue');
// prettier-ignore
const LanguageUpdate = () => import('@/entities/language/language-update.vue');
// prettier-ignore
const LanguageDetails = () => import('@/entities/language/language-details.vue');
// prettier-ignore
const Giacenze = () => import('@/entities/giacenze/giacenze.vue');
// prettier-ignore
const Attachment = () => import('@/entities/attachment/attachment.vue');
// prettier-ignore
const AttachmentUpdate = () => import('@/entities/attachment/attachment-update.vue');
// prettier-ignore
const AttachmentDetails = () => import('@/entities/attachment/attachment-details.vue');
// prettier-ignore
const NotificationThreshold = () => import('@/entities/notification-threshold/notification-threshold.vue');
// prettier-ignore
const NotificationThresholdUpdate = () => import('@/entities/notification-threshold/notification-threshold-update.vue');
// prettier-ignore
const NotificationThresholdDetails = () => import('@/entities/notification-threshold/notification-threshold-details.vue');
// prettier-ignore
const BadgeEvent = () => import('@/entities/badge-event/badge-event.vue');
// prettier-ignore
const BadgeEventDetails = () => import('@/entities/badge-event/badge-event-details.vue');
// prettier-ignore
const ApiEvent = () => import('@/entities/api-event/api-event.vue');
// prettier-ignore
const ApiEventDetails = () => import('@/entities/api-event/api-event-details.vue');
// prettier-ignore
const Event = () => import('@/entities/event/event.vue');
// prettier-ignore
const ProdottoObjectMetadataCondition = () => import('@/entities/prodotto-object-metadata-condition/prodotto-object-metadata-condition.vue');
// prettier-ignore
const ProdottoObjectMetadataConditionUpdate = () => import('@/entities/prodotto-object-metadata-condition/prodotto-object-metadata-condition-update.vue');
// prettier-ignore
const ProdottoObjectMetadataConditionDetails = () => import('@/entities/prodotto-object-metadata-condition/prodotto-object-metadata-condition-details.vue');
// prettier-ignore
const PeopleMetadataCondition = () => import('@/entities/people-metadata-condition/people-metadata-condition.vue');
// prettier-ignore
const PeopleMetadataConditionUpdate = () => import('@/entities/people-metadata-condition/people-metadata-condition-update.vue');
// prettier-ignore
const PeopleMetadataConditionDetails = () => import('@/entities/people-metadata-condition/people-metadata-condition-details.vue');
// prettier-ignore
const ImportExportSchedule = () => import('@/entities/import-export-schedule/import-export-schedule.vue');
// prettier-ignore
const ImportExportScheduleUpdate = () => import('@/entities/import-export-schedule/import-export-schedule-update.vue');
// prettier-ignore
const ImportExportScheduleDetails = () => import('@/entities/import-export-schedule/import-export-schedule-details.vue');
// prettier-ignore
const ImportExportSequence = () => import('@/entities/import-export-sequence/import-export-sequence.vue');
// prettier-ignore
const ImportExportSequenceUpdate = () => import('@/entities/import-export-sequence/import-export-sequence-update.vue');
// prettier-ignore
const ImportExportSequenceDetails = () => import('@/entities/import-export-sequence/import-export-sequence-details.vue');
// prettier-ignore
const ImportExportEntityMapper = () => import('@/entities/import-export-entity-mapper/import-export-entity-mapper.vue');
// prettier-ignore
const ImportExportEntityMapperUpdate = () => import('@/entities/import-export-entity-mapper/import-export-entity-mapper-update.vue');
// prettier-ignore
const ImportExportEntityMapperDetails = () => import('@/entities/import-export-entity-mapper/import-export-entity-mapper-details.vue');
// prettier-ignore
const ImportExportColumnMapper = () => import('@/entities/import-export-column-mapper/import-export-column-mapper.vue');
// prettier-ignore
const ImportExportColumnMapperUpdate = () => import('@/entities/import-export-column-mapper/import-export-column-mapper-update.vue');
// prettier-ignore
const ImportExportColumnMapperDetails = () => import('@/entities/import-export-column-mapper/import-export-column-mapper-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

const ImportWorkbox = () => import('@/entities/import/importWorkbox.vue');

const ImportMagazzino = () => import('@/entities/import/importMagazzino.vue');

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'setting',
      name: 'Setting',
      component: Setting,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'setting/new',
      name: 'SettingCreate',
      component: SettingUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'setting/:settingId/edit',
      name: 'SettingEdit',
      component: SettingUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'setting/:settingId/view',
      name: 'SettingView',
      component: SettingDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'people',
      name: 'People',
      component: People,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'people/new',
      name: 'PeopleCreate',
      component: PeopleUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.MANAGER] },
    },
    {
      path: 'people/:peopleId/edit',
      name: 'PeopleEdit',
      component: PeopleUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'people/:peopleId/view',
      name: 'PeopleView',
      component: PeopleDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'people-metadata',
      name: 'PeopleMetadata',
      component: PeopleMetadata,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'people-metadata/new',
      name: 'PeopleMetadataCreate',
      component: PeopleMetadataUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'people-metadata/:peopleMetadataId/edit',
      name: 'PeopleMetadataEdit',
      component: PeopleMetadataUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'people-metadata/:peopleMetadataId/view',
      name: 'PeopleMetadataView',
      component: PeopleMetadataDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'badge',
      name: 'Badge',
      component: Badge,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'badge/new',
      name: 'BadgeCreate',
      component: BadgeUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'badge/:badgeId/edit',
      name: 'BadgeEdit',
      component: BadgeUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'badge/:badgeId/view',
      name: 'BadgeView',
      component: BadgeDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'badge-history',
      name: 'BadgeHistory',
      component: BadgeHistory,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'badge-history/:badgeHistoryId/view',
      name: 'BadgeHistoryView',
      component: BadgeHistoryDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'identifier',
      name: 'Identifier',
      component: Identifier,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'identifier/new',
      name: 'IdentifierCreate',
      component: IdentifierUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'identifier/:identifierId/edit',
      name: 'IdentifierEdit',
      component: IdentifierUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'identifier/:identifierId/view',
      name: 'IdentifierView',
      component: IdentifierDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'group',
      name: 'Group',
      component: Group,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'group/new',
      name: 'GroupCreate',
      component: GroupUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'group/:groupId/edit',
      name: 'GroupEdit',
      component: GroupUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'group/:groupId/view',
      name: 'GroupView',
      component: GroupDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'department',
      name: 'Department',
      component: Department,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'department/new',
      name: 'DepartmentCreate',
      component: DepartmentUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'department/:departmentId/edit',
      name: 'DepartmentEdit',
      component: DepartmentUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'department/:departmentId/view',
      name: 'DepartmentView',
      component: DepartmentDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'group-permission',
      name: 'GroupPermission',
      component: GroupPermission,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'group-permission/new',
      name: 'GroupPermissionCreate',
      component: GroupPermissionUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'group-permission/:groupPermissionId/edit',
      name: 'GroupPermissionEdit',
      component: GroupPermissionUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'group-permission/:groupPermissionId/view',
      name: 'GroupPermissionView',
      component: GroupPermissionDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto',
      name: 'Prodotto',
      component: Prodotto,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto/new',
      name: 'ProdottoCreate',
      component: ProdottoUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto/:prodottoId/edit',
      name: 'ProdottoEdit',
      component: ProdottoUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto/:prodottoId/view',
      name: 'ProdottoView',
      component: ProdottoDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'kit',
      name: 'Kit',
      component: Kit,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'kit/new',
      name: 'KitCreate',
      component: KitUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'kit/:kitId/edit',
      name: 'KitEdit',
      component: KitUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'kit/:kitId/view',
      name: 'KitView',
      component: KitDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'variante',
      name: 'Variante',
      component: Variante,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'variante/new',
      name: 'VarianteCreate',
      component: VarianteUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'variante/:varianteId/edit',
      name: 'VarianteEdit',
      component: VarianteUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'variante/:varianteId/view',
      name: 'VarianteView',
      component: VarianteDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object',
      name: 'ProdottoObject',
      component: ProdottoObject,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object/:prodottoObjectId/view',
      name: 'ProdottoObjectView',
      component: ProdottoObjectDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata',
      name: 'ProdottoObjectMetadata',
      component: ProdottoObjectMetadata,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata/new',
      name: 'ProdottoObjectMetadataCreate',
      component: ProdottoObjectMetadataUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata/:prodottoObjectMetadataId/edit',
      name: 'ProdottoObjectMetadataEdit',
      component: ProdottoObjectMetadataUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata/:prodottoObjectMetadataId/view',
      name: 'ProdottoObjectMetadataView',
      component: ProdottoObjectMetadataDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata-value',
      name: 'ProdottoObjectMetadataValue',
      component: ProdottoObjectMetadataValue,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata-value/new',
      name: 'ProdottoObjectMetadataValueCreate',
      component: ProdottoObjectMetadataValueUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata-value/:prodottoObjectMetadataValueId/edit',
      name: 'ProdottoObjectMetadataValueEdit',
      component: ProdottoObjectMetadataValueUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata-value/:prodottoObjectMetadataValueId/view',
      name: 'ProdottoObjectMetadataValueView',
      component: ProdottoObjectMetadataValueDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'variante-attributo',
      name: 'VarianteAttributo',
      component: VarianteAttributo,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'variante-attributo/new',
      name: 'VarianteAttributoCreate',
      component: VarianteAttributoUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'variante-attributo/:varianteAttributoId/edit',
      name: 'VarianteAttributoEdit',
      component: VarianteAttributoUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'variante-attributo/:varianteAttributoId/view',
      name: 'VarianteAttributoView',
      component: VarianteAttributoDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'valore-attributo',
      name: 'ValoreAttributo',
      component: ValoreAttributo,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'valore-attributo/new',
      name: 'ValoreAttributoCreate',
      component: ValoreAttributoUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'valore-attributo/:valoreAttributoId/edit',
      name: 'ValoreAttributoEdit',
      component: ValoreAttributoUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'valore-attributo/:valoreAttributoId/view',
      name: 'ValoreAttributoView',
      component: ValoreAttributoDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'categoria',
      name: 'Categoria',
      component: Categoria,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'categoria/new',
      name: 'CategoriaCreate',
      component: CategoriaUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'categoria/:categoriaId/edit',
      name: 'CategoriaEdit',
      component: CategoriaUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'categoria/:categoriaId/view',
      name: 'CategoriaView',
      component: CategoriaDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'magazzino',
      name: 'Magazzino',
      component: Magazzino,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'magazzino/new',
      name: 'MagazzinoCreate',
      component: MagazzinoUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'magazzino/:magazzinoId/edit',
      name: 'MagazzinoEdit',
      component: MagazzinoUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'magazzino/:magazzinoId/view',
      name: 'MagazzinoView',
      component: MagazzinoDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'permesso-group',
      name: 'PermessoGroup',
      component: PermessoGroup,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'permesso-group/new',
      name: 'PermessoGroupCreate',
      component: PermessoGroupUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'permesso-group/:permessoGroupId/edit',
      name: 'PermessoGroupEdit',
      component: PermessoGroupUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'permesso-group/:permessoGroupId/view',
      name: 'PermessoGroupView',
      component: PermessoGroupDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'permesso-people',
      name: 'PermessoPeople',
      component: PermessoPeople,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'permesso-people/new',
      name: 'PermessoPeopleCreate',
      component: PermessoPeopleUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'permesso-people/:permessoPeopleId/edit',
      name: 'PermessoPeopleEdit',
      component: PermessoPeopleUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'permesso-people/:permessoPeopleId/view',
      name: 'PermessoPeopleView',
      component: PermessoPeopleDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'movimento',
      name: 'Movimento',
      component: Movimento,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER, Authority.USER] },
    },
    {
      path: 'movimento/:movimentoId/view',
      name: 'MovimentoView',
      component: MovimentoDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER, Authority.USER] },
    },
    {
      path: 'alloggiamento',
      name: 'Alloggiamento',
      component: Alloggiamento,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.WAREHOUSE_WORKER] },
    },
    {
      path: 'alloggiamento/new',
      name: 'AlloggiamentoCreate',
      component: AlloggiamentoUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'alloggiamento/:alloggiamentoId/edit',
      name: 'AlloggiamentoEdit',
      component: AlloggiamentoUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.WAREHOUSE_WORKER] },
    },
    {
      path: 'alloggiamento/:alloggiamentoId/view',
      name: 'AlloggiamentoView',
      component: AlloggiamentoDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.WAREHOUSE_WORKER] },
    },
    {
      path: 'vassoio-workbox',
      name: 'VassoioWorkbox',
      component: VassoioWorkbox,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'vassoio-workbox/new',
      name: 'VassoioWorkboxCreate',
      component: VassoioWorkboxUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'vassoio-workbox/:vassoioWorkboxId/edit',
      name: 'VassoioWorkboxEdit',
      component: VassoioWorkboxUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'vassoio-workbox/:vassoioWorkboxId/view',
      name: 'VassoioWorkboxView',
      component: VassoioWorkboxDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'impostazioni-scorte',
      name: 'ImpostazioniScorte',
      component: ImpostazioniScorte,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'impostazioni-scorte/new',
      name: 'ImpostazioniScorteCreate',
      component: ImpostazioniScorteUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'impostazioni-scorte/:impostazioniScorteId/edit',
      name: 'ImpostazioniScorteEdit',
      component: ImpostazioniScorteUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'impostazioni-scorte/:impostazioniScorteId/view',
      name: 'ImpostazioniScorteView',
      component: ImpostazioniScorteDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-rule',
      name: 'NotificationRule',
      component: NotificationRule,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-rule/new',
      name: 'NotificationRuleCreate',
      component: NotificationRuleUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-rule/:notificationRuleId/edit',
      name: 'NotificationRuleEdit',
      component: NotificationRuleUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-rule/:notificationRuleId/view',
      name: 'NotificationRuleView',
      component: NotificationRuleDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-web',
      name: 'NotificationWeb',
      component: NotificationWeb,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-web/new',
      name: 'NotificationWebCreate',
      component: NotificationWebUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-web/:notificationWebId/edit',
      name: 'NotificationWebEdit',
      component: NotificationWebUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-web/:notificationWebId/view',
      name: 'NotificationWebView',
      component: NotificationWebDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-email',
      name: 'NotificationEmail',
      component: NotificationEmail,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-email/new',
      name: 'NotificationEmailCreate',
      component: NotificationEmailUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-email/:notificationEmailId/edit',
      name: 'NotificationEmailEdit',
      component: NotificationEmailUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-email/:notificationEmailId/view',
      name: 'NotificationEmailView',
      component: NotificationEmailDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'session',
      name: 'Session',
      component: Session,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'session/:sessionId/view',
      name: 'SessionView',
      component: SessionDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'language',
      name: 'Language',
      component: Language,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'language/:languageId/edit',
      name: 'LanguageEdit',
      component: LanguageUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'language/:languageId/view',
      name: 'LanguageView',
      component: LanguageDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: '/giacenze',
      name: 'Giacenze',
      component: Giacenze,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER, Authority.WAREHOUSE_WORKER] },
    },
    {
      path: '/import-workbox',
      name: 'ImportWorkbox',
      component: ImportWorkbox,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: '/import-magazzino',
      name: 'ImportMagazzino',
      component: ImportMagazzino,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'attachment/:attachmentId/view',
      name: 'AttachmentView',
      component: AttachmentDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-threshold',
      name: 'NotificationThreshold',
      component: NotificationThreshold,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-threshold/new',
      name: 'NotificationThresholdCreate',
      component: NotificationThresholdUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-threshold/:notificationThresholdId/edit',
      name: 'NotificationThresholdEdit',
      component: NotificationThresholdUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'notification-threshold/:notificationThresholdId/view',
      name: 'NotificationThresholdView',
      component: NotificationThresholdDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'badge-event',
      name: 'BadgeEvent',
      component: BadgeEvent,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER, Authority.USER] },
    },
    {
      path: 'badge-event/:badgeEventId/view',
      name: 'BadgeEventView',
      component: BadgeEventDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER, Authority.USER] },
    },
    {
      path: '/giacenze',
      name: 'Giacenze',
      component: Giacenze,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER, Authority.WAREHOUSE_WORKER] },
    },
    {
      path: 'api-event',
      name: 'ApiEvent',
      component: ApiEvent,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'event',
      name: 'Event',
      component: Event,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'api-event/:apiEventId/view',
      name: 'ApiEventView',
      component: ApiEventDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: '/giacenze',
      name: 'Giacenze',
      component: Giacenze,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER, Authority.WAREHOUSE_WORKER] },
    },
    {
      path: 'prodotto-object-metadata-condition',
      name: 'ProdottoObjectMetadataCondition',
      component: ProdottoObjectMetadataCondition,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata-condition/new',
      name: 'ProdottoObjectMetadataConditionCreate',
      component: ProdottoObjectMetadataConditionUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata-condition/:prodottoObjectMetadataConditionId/edit',
      name: 'ProdottoObjectMetadataConditionEdit',
      component: ProdottoObjectMetadataConditionUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'prodotto-object-metadata-condition/:prodottoObjectMetadataConditionId/view',
      name: 'ProdottoObjectMetadataConditionView',
      component: ProdottoObjectMetadataConditionDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'people-metadata-condition',
      name: 'PeopleMetadataCondition',
      component: PeopleMetadataCondition,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'people-metadata-condition/new',
      name: 'PeopleMetadataConditionCreate',
      component: PeopleMetadataConditionUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'people-metadata-condition/:peopleMetadataConditionId/edit',
      name: 'PeopleMetadataConditionEdit',
      component: PeopleMetadataConditionUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'people-metadata-condition/:peopleMetadataConditionId/view',
      name: 'PeopleMetadataConditionView',
      component: PeopleMetadataConditionDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN, Authority.MANAGER] },
    },
    {
      path: 'import-export-schedule',
      name: 'ImportExportSchedule',
      component: ImportExportSchedule,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-schedule/new',
      name: 'ImportExportScheduleCreate',
      component: ImportExportScheduleUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-schedule/:importExportScheduleId/edit',
      name: 'ImportExportScheduleEdit',
      component: ImportExportScheduleUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-schedule/:importExportScheduleId/view',
      name: 'ImportExportScheduleView',
      component: ImportExportScheduleDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-sequence',
      name: 'ImportExportSequence',
      component: ImportExportSequence,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-sequence/new',
      name: 'ImportExportSequenceCreate',
      component: ImportExportSequenceUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-sequence/:importExportSequenceId/edit',
      name: 'ImportExportSequenceEdit',
      component: ImportExportSequenceUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-sequence/:importExportSequenceId/view',
      name: 'ImportExportSequenceView',
      component: ImportExportSequenceDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-entity-mapper',
      name: 'ImportExportEntityMapper',
      component: ImportExportEntityMapper,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-entity-mapper/new',
      name: 'ImportExportEntityMapperCreate',
      component: ImportExportEntityMapperUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-entity-mapper/:importExportEntityMapperId/edit',
      name: 'ImportExportEntityMapperEdit',
      component: ImportExportEntityMapperUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-entity-mapper/:importExportEntityMapperId/view',
      name: 'ImportExportEntityMapperView',
      component: ImportExportEntityMapperDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-column-mapper',
      name: 'ImportExportColumnMapper',
      component: ImportExportColumnMapper,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-column-mapper/new',
      name: 'ImportExportColumnMapperCreate',
      component: ImportExportColumnMapperUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-column-mapper/:importExportColumnMapperId/edit',
      name: 'ImportExportColumnMapperEdit',
      component: ImportExportColumnMapperUpdate,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    {
      path: 'import-export-column-mapper/:importExportColumnMapperId/view',
      name: 'ImportExportColumnMapperView',
      component: ImportExportColumnMapperDetails,
      meta: { authorities: [Authority.ADMIN, Authority.TECHNICIAN] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
