var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/prodotto" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "glasses" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.prodotto")),
              },
            },
            [_vm._v("Prodotto")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/variante-attributo" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "book-bible" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.varianteAttributo")
                ),
              },
            },
            [_vm._v("Variante Attributo")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/categoria" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "bookmark" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(_vm.$t("global.menu.entities.categoria")),
              },
            },
            [_vm._v("Categoria")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/prodotto-object-metadata" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "screwdriver" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.prodottoObjectMetadata")
                ),
              },
            },
            [_vm._v("Prodotto Object Metadata")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/prodotto-object" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "heart-pulse" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.prodottoObject")
                ),
              },
            },
            [_vm._v("Prodotto Object")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.TECHNICIAN])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/import-workbox" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "file-import" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.import")),
                  },
                },
                [_vm._v("Import")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }