var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.MANAGER, _vm.TECHNICIAN])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/event" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "globe" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.event")),
                  },
                },
                [_vm._v("Event")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.MANAGER, _vm.TECHNICIAN, _vm.USER]) &&
      _vm.distributorEnabled
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/movimento" } },
            [
              _c("font-awesome-icon", {
                attrs: { icon: "money-bill-transfer" },
              }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.movimento")
                    ),
                  },
                },
                [_vm._v("Movimento")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.MANAGER, _vm.TECHNICIAN, _vm.USER])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/badge-event" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "id-badge" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.badgeEvent")
                    ),
                  },
                },
                [_vm._v("Badge Event")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.MANAGER, _vm.TECHNICIAN])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/api-event" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "code" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.apiEvent")
                    ),
                  },
                },
                [_vm._v("Api Event")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([
        _vm.ADMIN,
        _vm.MANAGER,
        _vm.TECHNICIAN,
        _vm.WAREHOUSE_WORKER,
      ]) && _vm.distributorEnabled
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/giacenze" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "cubes-stacked" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("global.menu.entities.giacenze")
                    ),
                  },
                },
                [_vm._v("Giacenze")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasAnyAuthority([_vm.ADMIN, _vm.TECHNICIAN])
        ? _c(
            "b-dropdown-item",
            { attrs: { to: "/session" } },
            [
              _c("font-awesome-icon", { attrs: { icon: "circle-nodes" } }),
              _vm._v(" "),
              _c(
                "span",
                {
                  domProps: {
                    textContent: _vm._s(_vm.$t("global.menu.entities.session")),
                  },
                },
                [_vm._v("Session")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }