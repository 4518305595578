var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-dropdown-item",
        { attrs: { to: "/notification-rule" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "pen-ruler" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.notificationRule")
                ),
              },
            },
            [_vm._v("Notification Rule")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/notification-web" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "globe" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.notificationWeb")
                ),
              },
            },
            [_vm._v("Notification Web")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/notification-email" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "inbox" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.notificationEmail")
                ),
              },
            },
            [_vm._v("Notification Email")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-dropdown-item",
        { attrs: { to: "/prodotto-object-metadata-condition" } },
        [
          _c("font-awesome-icon", { attrs: { icon: "filter" } }),
          _vm._v(" "),
          _c(
            "span",
            {
              domProps: {
                textContent: _vm._s(
                  _vm.$t("global.menu.entities.prodottoObjectMetadataCondition")
                ),
              },
            },
            [_vm._v("Prodotto Object Metadata Condition")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }