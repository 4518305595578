var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-navbar",
    {
      staticClass: "jh-navbar",
      attrs: { "data-cy": "navbar", toggleable: "md", type: "dark" },
    },
    [
      _c(
        "b-navbar-brand",
        { staticClass: "logo", attrs: { "b-link": "", to: "/" } },
        [
          _c("span", { staticClass: "logo-img" }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "navbar-title",
              domProps: { textContent: _vm._s(_vm.$t("global.title")) },
            },
            [_vm._v("magazzino")]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "navbar-version" }, [
            _vm._v(_vm._s(_vm.version)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-navbar-toggle",
        {
          staticClass: "jh-navbar-toggler d-lg-none",
          attrs: {
            right: "",
            href: "javascript:void(0);",
            "data-toggle": "collapse",
            target: "header-tabs",
            "aria-expanded": "false",
            "aria-label": "Toggle navigation",
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "bars" } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { "is-nav": "", id: "header-tabs" } },
        [
          _c(
            "b-navbar-nav",
            { staticClass: "ml-auto" },
            [
              _c("b-nav-item", { attrs: { to: "/", exact: "" } }, [
                _c(
                  "span",
                  [
                    _c("font-awesome-icon", { attrs: { icon: "home" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(_vm.$t("global.menu.home")),
                        },
                      },
                      [_vm._v("Home")]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.authenticated &&
              _vm.hasAnyAuthority([_vm.ADMIN, _vm.MANAGER, _vm.TECHNICIAN])
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "registry-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "address-book" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.registry")
                                ),
                              },
                            },
                            [_vm._v("Registry")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("registry-menu"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated &&
              _vm.hasAnyAuthority([_vm.ADMIN, _vm.MANAGER, _vm.TECHNICIAN]) &&
              _vm.distributorEnabled
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "product-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "shirt" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.product")
                                ),
                              },
                            },
                            [_vm._v("Product")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("product-menu"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated &&
              _vm.hasAnyAuthority([
                _vm.ADMIN,
                _vm.TECHNICIAN,
                _vm.MANAGER,
                _vm.WAREHOUSE_WORKER,
              ]) &&
              _vm.distributorEnabled
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "warehouse-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "warehouse" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.warehouse")
                                ),
                              },
                            },
                            [_vm._v("Warehouse")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("warehouse-menu"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated &&
              _vm.hasAnyAuthority([_vm.ADMIN, _vm.MANAGER, _vm.TECHNICIAN]) &&
              _vm.distributorEnabled
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "notification-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "bell" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.notification")
                                ),
                              },
                            },
                            [_vm._v("Notification")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("notification-menu"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      attrs: {
                        right: "",
                        id: "info-menu",
                        "active-class": "active",
                        "data-cy": "entity",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "line-chart" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.info")
                                ),
                              },
                            },
                            [_vm._v("Info")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("info-menu"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.hasAnyAuthority("ROLE_ADMIN") && _vm.authenticated
                ? _c(
                    "b-nav-item-dropdown",
                    {
                      staticClass: "pointer",
                      class: {
                        "router-link-active": _vm.subIsActive("/admin"),
                      },
                      attrs: {
                        right: "",
                        id: "admin-menu",
                        "active-class": "active",
                        "data-cy": "adminMenu",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "navbar-dropdown-menu",
                          attrs: { slot: "button-content" },
                          slot: "button-content",
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "users-cog" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "no-bold",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.admin.main")
                                ),
                              },
                            },
                            [_vm._v("Administration")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/setting" } },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "gears" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.setting")
                                ),
                              },
                            },
                            [_vm._v("Setting")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/import-export-entity-mapper" } },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "table" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "global.menu.entities.importExportEntityMapper"
                                  )
                                ),
                              },
                            },
                            [_vm._v("Import Export Entity Mapper")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/import-export-schedule" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "calendar-days" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t(
                                    "global.menu.entities.importExportSchedule"
                                  )
                                ),
                              },
                            },
                            [_vm._v("Import Export Schedule")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.distributorEnabled
                        ? _c(
                            "b-dropdown-item",
                            { attrs: { to: "/import-magazzino" } },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "file-import" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.entities.import")
                                    ),
                                  },
                                },
                                [_vm._v("Import")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-dropdown-item",
                        { attrs: { to: "/language" } },
                        [
                          _c("font-awesome-icon", { attrs: { icon: "flag" } }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.entities.language")
                                ),
                              },
                            },
                            [_vm._v("Language")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.openAPIEnabled
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: {
                                to: "/admin/docs",
                                "active-class": "active",
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "book" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.apidocs")
                                    ),
                                  },
                                },
                                [_vm._v("API")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.inProduction
                        ? _c(
                            "b-dropdown-item",
                            {
                              attrs: { href: "./h2-console/", target: "_tab" },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: "database" },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.$t("global.menu.admin.database")
                                    ),
                                  },
                                },
                                [_vm._v("Database")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-nav-item-dropdown",
                {
                  staticClass: "pointer",
                  class: { "router-link-active": _vm.subIsActive("/account") },
                  attrs: {
                    right: "",
                    href: "javascript:void(0);",
                    id: "account-menu",
                    "active-class": "active",
                    "data-cy": "accountMenu",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "navbar-dropdown-menu",
                      attrs: { slot: "button-content" },
                      slot: "button-content",
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "user" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "no-bold",
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("global.menu.account.main")
                            ),
                          },
                        },
                        [_vm._v(" Account ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "logout",
                            id: "logout",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.logout()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-out-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.logout")
                                ),
                              },
                            },
                            [_vm._v("Sign out")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.authenticated
                    ? _c(
                        "b-dropdown-item",
                        {
                          attrs: {
                            "data-cy": "login",
                            id: "login",
                            "active-class": "active",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openLogin()
                            },
                          },
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "sign-in-alt" },
                          }),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.account.login")
                                ),
                              },
                            },
                            [_vm._v("Sign in")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }