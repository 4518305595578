import { Component, Inject, Vue } from 'vue-property-decorator';
import AccountService from '@/account/account.service';
import { Authority } from '@/shared/security/authority';

export default class AuthorityClass extends Vue {
  public ADMIN = Authority.ADMIN;
  public MANAGER = Authority.MANAGER;
  public TECHNICIAN = Authority.TECHNICIAN;
  public USER = Authority.USER;
  public WAREHOUSE_WORKER = Authority.WAREHOUSE_WORKER;

  @Inject('accountService') private accountService: () => AccountService;

  private hasAnyAuthorityValues = {};

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then(value => {
        if (this.hasAnyAuthorityValues[authorities] !== value) {
          this.hasAnyAuthorityValues = { ...this.hasAnyAuthorityValues, [authorities]: value };
        }
      });
    return this.hasAnyAuthorityValues[authorities] ?? false;
  }
}
