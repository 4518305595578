import { Component, Inject, Vue } from 'vue-property-decorator';
import LoginService from '@/account/login.service';
import TranslationService from '@/locale/translation.service';

import RegistryMenu from '@/entities/registry-menu.vue';
import ProductMenu from '@/entities/product-menu.vue';
import WarehouseMenu from '@/entities/warehouse-menu.vue';
import NotificationMenu from '@/entities/notification-menu.vue';
import InfoMenu from '@/entities/info-menu.vue';
import AuthorityClass from '@/entities/authority';

@Component({
  components: {
    'registry-menu': RegistryMenu,
    'product-menu': ProductMenu,
    'warehouse-menu': WarehouseMenu,
    'notification-menu': NotificationMenu,
    'info-menu': InfoMenu,
  },
})
export default class JhiNavbar extends AuthorityClass {
  @Inject('loginService')
  private loginService: () => LoginService;
  @Inject('translationService') private translationService: () => TranslationService;

  public version = 'v' + VERSION;
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;

  created() {
    const currentLanguage = Object.keys(this.languages).includes(navigator.language) ? navigator.language : this.currentLanguage;
    this.translationService().refreshTranslation(currentLanguage);
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): Promise<any> {
    return this.loginService()
      .logout()
      .then(response => {
        this.$store.commit('logout');
        window.location.href = response.data.logoutUrl;
        const next = response.data?.logoutUrl ?? '/';
        if (this.$route.path !== next) {
          return this.$router.push(next);
        }
      });
    return Promise.resolve(this.$router.currentRoute);
  }

  public openLogin(): void {
    this.loginService().login();
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }

  public get distributorEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('distributor') > -1;
  }
}
